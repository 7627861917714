import hpupm from '../../assets/images/hpupm.png';
import logo2 from '../../assets/images/logo-2.jpg';
import logo3 from '../../assets/images/temp-logo.png';

const obj=[
    { value: 'logo1', label: <div><img src={hpupm} height="40px" /></div>,img:hpupm },
    { value: 'logo2', label:<div><img src={logo2} height="40px" /></div>,img:logo2 },
    { value: 'logo3',label:<div><img src={logo3} height="40px" /></div>,img:logo3  },
]

export default obj;