const MECHANICAL_THROMBECTOMY = `MECHANICAL THROMBECTOMY

Procedure: 

Proceduralist:

Assistants/A.I:

Indication: 
Pre procedure Imaging findings: (modality type)  Occlusion Site/Lesion:   Single / Multiple , Location:
NIHSS: 
Stroke Onset:
Thrombolysis:  Yes/No
Code:  R / Y / G
Arrival Time:
Decision Time MT:
Anaesthesia: None/ Intubated /Sedated

Puncture Time:
Puncture Site:

Complication: 
Closure device:


Findings:
Pre-MT:

Post-MT:

Recanalisation Time:

TICI:  1/ 2A /2B / 3

Collaterals grading: 

Equipments:


Thrombectomy Method: Aspiration / Stentriever/ Combined /Others

Total Passes:
- Aspiration:
- Stentriever:

Other


Pre Procedure CBCT: Bleed/ No Bleed
Post Procedure CBCT: Bleed/ No Bleed



VITALS: 
- BP
- PR
- SPO2

Plan:
1. Transfer to: ICU/  Stroke Ward/ Others
2. CRIB 6 hours
3. Watch out for puncture side hematoma.
`

export default MECHANICAL_THROMBECTOMY;
